<template>
    <div>
        <div class="fb-header">
            <img :src="whiteLabelInfo.logoUrl" :alt="whiteLabelInfo.nombre" style="margin: auto; max-width: 150px;" class="logo p-1"/>
        </div>
        <!-- Video en bucle que se muestra en pantalla completa -->
        <video
                v-if="!showSignature"
                ref="video"
                @loadedmetadata="playFullScreen"
                loop
                autoplay
                muted
                class="video-fullscreen"
        >
            <source src="https://anysubscriptions-public.s3.us-east-2.amazonaws.com/_comasa/Energuate+Version+Dos+Tablets.mp4" type="video/mp4" />
            Tu navegador no soporta la etiqueta de video.
        </video>

        <!-- Firma en pantalla completa -->
        <div v-if="showSignature" class="signature-container">

                <canvas ref="signaturePad" class="signature-pad"></canvas>
                <button class="btn btn-outline-dark" @click="clearSignature">Limpiar Firma</button>
                <button class="btn btn-outline-success" @click="saveSignature">Guardar Firma</button>

        </div>
    </div>
</template>

<script>
import SignaturePad from 'signature_pad';
import Layout from "../../../../layout/admin/Layout.vue";
import {mapGetters} from "vuex";
import {CONFIG} from '/src/config';
import {store} from "src/store";
import API from "../../../../core/Api";

export default {
    components: {Layout},
    data() {
        return {
            showSignature: false,
            signaturePad: null,
            cotizacionId: false,
            serviceCheckInterval: null,
            signatureInitialized: false,  // Nueva bandera
        };
    },
    mounted() {
        // Iniciar el servicio para verificar el ID cada 3 segundos
        this.serviceCheckInterval = setInterval(this.checkForSignature, 3000);

        // Ajustar el tamaño del canvas cuando se cambie el tamaño de la ventana
        window.addEventListener('resize', this.resizeCanvas);
    },
    beforeUnmount() {
        // Limpiar el intervalo y eliminar el event listener cuando se destruya el componente
        clearInterval(this.serviceCheckInterval);
        window.removeEventListener('resize', this.resizeCanvas);
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
        })
    },
    methods: {
        playFullScreen() {
            const videoElement = this.$refs.video;

            if (videoElement.requestFullscreen) {
                videoElement.requestFullscreen();
            } else if (videoElement.webkitRequestFullscreen) { // Safari y versiones antiguas de iOS
                videoElement.webkitRequestFullscreen();
            }
        },
        checkForSignature() {
            const self = this;
            const searchUrl = 'orders/get/sign/cotizaciones';
            API.send('GET', searchUrl, {},
                function (response) {
                    if (response.status !== 0) {
                        self.cotizacionId = response.data.id_cotizacion;
                        self.showSignature = true;
                        self.signatureInitialized = true;  // Marcar como inicializado
                        self.$nextTick(() => {
                            self.initSignaturePad();
                        });
                        // Detener el intervalo de verificación una vez que se muestra la firma
                        clearInterval(self.serviceCheckInterval);
                    }
                },
                function (data) {
                    self.cotizacionId = false
                    self.showSignature = false;
                    self.signatureInitialized = false;  // Marcar como inicializado
                    return false;
                });

        },
        initSignaturePad() {
            const canvas = this.$refs.signaturePad;
            this.resizeCanvas();
            this.signaturePad = new SignaturePad(canvas, {
                minWidth: 1,
                maxWidth: 3,
                penColor: "rgb(0, 0, 0)",
            });
        },
        resizeCanvas() {
            const canvas = this.$refs.signaturePad;
            const ratio = Math.max(window.devicePixelRatio || 1, 1); // Ajuste para pantallas de alta resolución

            // Ajusta el tamaño del canvas manteniendo la escala de relación de píxeles
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;
            canvas.getContext('2d').scale(ratio, ratio); // Escalar el contexto del canvas
        },
        clearSignature() {
            if (this.signaturePad) {
                this.signaturePad.clear();
            }
        },

        saveSignature() {
            if (this.signaturePad) {

                // Obtener la firma en formato base64
                const signatureData = this.signaturePad.toDataURL('image/png');

                // Crear un objeto FormData
                const formData = new FormData();
                formData.append('file', this.dataURLtoFile(signatureData, 'signature.png'));
                formData.append('cotizacionId', this.cotizacionId); // ID de la cotización
                formData.append('category', 'D'); // Categoría, ajustar según corresponda

                // Crear el objeto XMLHttpRequest
                const request = new XMLHttpRequest();
                request.open('POST', CONFIG.apiUrl + '/orders/sign/cotizacion'); // Ajusta la URL al endpoint
                request.setRequestHeader('Authorization', 'Bearer ' + store.getters.AuthGetToken);

                // Progreso de la carga
                request.upload.onprogress = (e) => {
                    if (e.lengthComputable) {
                        const percentComplete = (e.loaded / e.total) * 100;
                        console.log(`Progress: ${percentComplete}%`);
                    }
                };

                // Manejo de la respuesta
                request.onload = () => {
                    if (request.status >= 200 && request.status < 300) {
                        const response = JSON.parse(request.responseText);
                        if (response.status === 1) {
                            console.log('Firma guardada con éxito:', response);
                            this.signatureInitialized = false;
                            this.showSignature = false;
                            this.serviceCheckInterval = setInterval(this.checkForSignature, 3000);
                            // Aquí puedes manejar la respuesta, e.g., mostrar un mensaje de éxito
                        } else {
                            console.error('Error al guardar firma:', response.message);
                            // Manejar error
                        }
                    } else {
                        console.error('Error de conexión');
                    }
                };

                // Manejo de errores
                request.onerror = () => {
                    console.error('Error de conexión');
                };

                // Enviar la solicitud
                request.send(formData);
            }
        },
        dataURLtoFile(dataURL, filename) {
            // Convierte una data URL a un objeto File
            const [header, data] = dataURL.split(',');
            const mime = header.match(/:(.*?);/)[1];
            const binary = atob(data);
            const array = [];
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new File([new Uint8Array(array)], filename, { type: mime });
        }
    }
};
</script>

<style scoped>
.video-fullscreen {
    width: 100vw; /* Asegura que ocupe todo el ancho de la ventana */
    height: 100vh; /* Asegura que ocupe todo el alto de la ventana */
    object-fit: contain; /* Mantiene la relación de aspecto */
    background-color: black; /* Añadir un fondo negro para las áreas vacías */
}


.signature-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signature-pad {
    border: 2px solid #000;
    width: 90%;
    height: 60%;
}

button {
    margin-top: 10px;
}
</style>
